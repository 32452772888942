<template>
  <v-container id="user-profile" fluid tag="section" style="margin-top: 100px">
    <v-row justify="center" align-center>
      <v-col cols="6" md="6">
        <v-layout justify-center align-center>
          <v-flex shrink>
            <img
              src="/images/logo.png"
              alt=""
              width="200"
              class=""
              style="margin-bottom: 50px"
            />
          </v-flex>
        </v-layout>
        <v-alert type="error" v-if="errMsg">
          {{ errMsg }}
        </v-alert>
        <v-alert type="success" v-if="successMsg"> Login Successful </v-alert>
        <base-material-card>
          <template v-slot:heading>
            <h1 class="display-6 text-left">Admin</h1>

            <div class="subtitle-1 font-weight-light">
              Please Login To Continue
            </div>
          </template>

          <v-form @submit.prevent="signIn">
            <v-container class="py-0">
              <v-col cols="12" md="12">
                <v-text-field
                  label="Email"
                  class="purple-input"
                  v-model="user.email"
                />
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Password"
                  class="purple-input"
                  type="password"
                  v-model="user.password"
                />
              </v-col>

              <v-row>
               
                <v-col cols="12" class="text-right">
                  <v-btn type="submit" color="success" class="mr-0" block>
                    <v-icon class="mr-2">mdi-content-save</v-icon>
                    Sign In
                  </v-btn>
                </v-col>
                 <!-- <v-col cols="12">
                  <div class="text-center">
                    <router-link to="/auth/signup">
                      Sign Up for An Account
                    </router-link>
                  </div>
                  <div class="text-center">
                    <router-link to="/auth/forgot-password">
                      Forgot Password?
                    </router-link>
                  </div>
                </v-col> -->
                
              </v-row>
              <div class="text-center subtitle-1 font-weight-light my-3 primary--text">
                <router-link to="forgot-password">
                  Forgot Your Password?
                </router-link>
                </div>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: "superadmin@hyphen.com",
        password: "string",
      },
      errMsg: false,
      successMsg: false,
      profile: {
        propertyId: "",
        propertyCompleted: false,
      },
      
    };
  },

  methods: {
    signIn() {
      this.$Auth
        .signIn(this.user)
        .then((data) => {
          this.errMsg = false;
          this.successMsg = true;
          this.$store.commit("setLoggedInUser", data.data.data);
          
          this.getNavigation();
        })
        .catch((error) => {
          console.log(error);
          this.errMsg = error.response.data.message;
          this.successMsg = false;
        });
    },
    getOwnDetail(){
           this.$API
            .getOwnDetail()
            .then((data) => {
              
              this.$store.commit("setLoggedInUserDetails", data.data.data);
            })
            .catch((error) => {
              console.log(error);
            });
    },
    getNavigation() {
      this.$router.push({path:'/'});
    },
 
   
  },
};
</script>

<style scoped>
</style>